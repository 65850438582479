import React, { useEffect, useCallback, useState } from "react"
import Layout from "../components/layout"
import socketIOClient from "socket.io-client";
import Bg from '../images/bg_wall2.jpg';
import Burger from '../images/burger.gif';
import '../scss/warten.scss';
import Alert from '../images/alert.wav';


const ENDPOINT = "https://connect.helmarts.at/";
const WartenPage = () => {

  const [socket, setSocket] = useState();
  const [ready, setReady] = useState(false);
  const [billNo, setBillNo] = useState(0);
  const [finish, setFinish] = useState(false);
  const [disconnected, setDisconnected] = useState(false);

  useEffect(() => {
    const so = socketIOClient(ENDPOINT, {
      path: '/socket'
    });
    so.on("connect", () => {
      console.log('connect 1');
      setReady(true);
      setDisconnected(false);
      setSocket(so);
      
    });
    so.on('disconnect', () => {
      setDisconnected(true);
    })
  }, []);

  useEffect(() => {
    if (socket) {
      const audio = new Audio(Alert);

      if (typeof audio.loop == 'boolean') {
        audio.loop = true;
      }
      else {
        audio.addEventListener('ended', function () {
          this.currentTime = 0;
          this.play();
        }, false);
      }

      socket.on('ready', (bill) => {
        setFinish(true);
        setBillNo(0);
        socket.disconnect();
        setInterval(() => {
          window.navigator.vibrate(200);
        }, 800);
        audio.play();
      })
      
    }
  }, [socket])

  useEffect(() => {
    if (!finish && socket && billNo) {
      socket.on('connect', () => socket.emit('bill', billNo))
    }
  }, [finish, socket, billNo])

  useEffect(() => {
    if (billNo && !ready) {
      socket.on("reconnect", () => {
        console.log('reconnect');
        setTimeout(() => {
          socket.emit('bill', billNo);
        }, 2000);
      });
      
      socket.on("connect", () => {
        console.log('connect 2');
        setTimeout(() => {
          socket.emit('bill', billNo);
          setDisconnected(false);
        }, 1000);
      });
    }
  }, [socket, billNo, ready]);

  const handler = useCallback(
    (e) => {
      e.preventDefault();
      const no = document.getElementById('billno').value;
      setBillNo(no)
      if (+no > 0) {
        socket.emit('bill', +no);
      }
    },
    [socket]
  );

  const redirect = useCallback(
    () => {
      window.location = '/';
    },
    [],
  )

  return (
    <Layout>
      <div style={{
        height: '500px',
        backgroundImage: `url(${Bg})`,
        borderLeft: '3px solid #EC2E21',
        borderRight: '3px solid #EC2E21'
      }} className={finish ? 'alert-bg' : ''}>
        <div className="h-5"><br /><br /></div>
        <div className="row">
          <div className="col-1 col-md-2 col-lg-3"></div>

          {finish ? <div className="col bg-white mx-2 py-4 shadow-lg rounded text-center">
            <h2>Ihre Bestellung ist bereit zur Abholung!</h2>
            <button className="btn btn-primary mt-3" onClick={redirect}>OK</button>
          </div> : +billNo > 0 ? <div style={{
            backgroundImage: `url(${Burger})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 3rem'
          }} className="col bg-white mx-2 p-4 shadow-lg rounded text-center">
            { disconnected ? <h1>VERBINDUNG UNTERBROCHEN!</h1> : <h1>Bitte warten</h1> }
            <strong>Rechnungsnummer: {billNo}</strong>
            <br /><br /><br /><br /><br /><br /><br />
          </div> : ready ? <div className="col bg-white mx-2 p-4 shadow-lg rounded">
            <form onSubmit={handler}>
              <div className="form-group row">
                <label htmlFor="billno" className="col-12 col-sm-4 col-form-label">Rechnungsnummer:</label>
                <div className="col-12 col-sm-8">
                  <input id="billno" name="billno" placeholder="12345" min="1" type="number" className="form-control" required="required" />
                  <button name="submit" className="btn mt-2 btn-primary">Benachrichtigen</button>
                </div>
              </div>
            </form>
          </div> : <div>connecting...</div>}
          <div className="col-1 col-md-2 col-lg-3"></div>
        </div>
      </div>
    </Layout>
  )
};

export default WartenPage